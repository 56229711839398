import { useEffect, useState } from "react";

const useValidacion = (stateInicial, validar, fn) => {
    const [valores, setValores] = useState(stateInicial);
    const [errores, setErrores] = useState({});
    const [formulario, setFormulario] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        if (formulario) {
            const noErrores = Object.keys(errores).length === 0;
            if (noErrores) {
                fn();
            }
            setFormulario(false);
        }
        // eslint-disable-next-line
    }, [errores]);

    const handleChange = e => {
        setValores({
            ...valores,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const erroresValidacion = validar(valores);
        setErrores(erroresValidacion);
        setFormulario(true);
    };

    return {
        valores,
        errores,
        handleChange,
        handleSubmit,
    };
}

export default useValidacion;