import { useState, useEffect } from 'react';
import firebase from '../lib';

function useAutenticacion() {
    const [usuarioAutenticado, setUsuarioAutenticado] = useState(null);

    useEffect(() => {
        const unsuscribe = firebase.auth.onAuthStateChanged(usuario => {
            if(usuario) {
                setUsuarioAutenticado(usuario);
            } else {
                setUsuarioAutenticado(null);
            }
        });
        return () => unsuscribe();
        // eslint-disable-next-line
    }, [])

    return usuarioAutenticado;
}

export default useAutenticacion;