export default function validarIniciarSesion(valores) {
    let errores = {};

    if (!valores.email) {
        errores.email = "El correo electrónico es obligatorio";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(valores.email)) {
        errores.email = "El correo electrónico no es válido";
    }

    if (!valores.password) {
        errores.password = "La contraseña es obligatorio";
    } else if (valores.password.length < 7) {
        errores.password = "La contraseña debe contener al menos 7 caracteres";
    }

    return errores;
}
