import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../components/layout/Container';

export default function PaginaNoEncontrada() {
    return (
        <Container>
            <div className='w-100 m-auto row'>
                <Link to="/" className='text-center mb-3'>
                    <button className="btn btn-outline-warning fw-bold">Volvamos a la zona segura</button>
                </Link>
                <h1 className='text-center p-0'>404 - Esta página no existe</h1>
                <Img src='/NotFund.gif' />
            </div>
        </Container>
    )
}

const Img = styled.img`
    width: 700px;
    margin: auto;
`;
