import React, { useContext } from 'react';
import CrmFinder from '../api/CrmFinder';
import { CrmContext } from '../context/CrmContext';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function AgregarCliente() {
    const { agregarCliente } = useContext(CrmContext);
    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

    const guardarCliente = async ({ name, email, quotes }) => {
        try {
            const response = await CrmFinder.post("/agregar-cliente", {
                name,
                email,
                quotes
            })
            agregarCliente(response.data.cliente);
            toast.success((t) => (
                <span className='fw-bold'>Se agrego el cliente</span>
            ));
        } catch (error) {
            console.log(error);
            toast.error((t) => (
                <span className='fw-bold'>No se pudo agregar el cliente</span>
            ));
        }
        reset({ name, email, quotes });
    }

    return (
        <form
            className='container-sm pt-3'
            autoComplete='on'
            onSubmit={handleSubmit(guardarCliente)}
        >
            <div className="form-floating">
                <input
                    type="text"
                    className="form-control fw-bold"
                    id="name"
                    placeholder="nombre"
                    {...register('name', { require: true, minLength: 4 })}
                />
                <label htmlFor="name">Nombre</label>
            </div>
            {errors.name && <span className='text-danger'>Debe contener almenos 4 letras</span>}
            <div className="form-floating mt-3">
                <input
                    type="email"
                    className="form-control fw-bold"
                    id="email"
                    placeholder="email"
                    // eslint-disable-next-line
                    {...register('email', { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                />
                <label htmlFor="email">Correo</label>
            </div>
            {errors.email && <span className='text-danger'>Introduzca un correo valido</span>}
            <div className="form-floating mt-3">
                <textarea
                    className="form-control fw-bold"
                    placeholder="Observacion"
                    id="quotes"
                    style={{ height: '100px' }}
                    {...register('quotes', { maxLength: 200 })}
                ></textarea>
                <label htmlFor="quotes">Nota</label>
            </div>
            {errors.quotes && <span className='text-danger'>Máximo 200 caracteres</span>}
            <div className="col-12">
                <button
                    className="btn btn-primary mt-3 fw-bold"
                    type="submit"
                    disabled={!isDirty || !isValid}
                >Guardar</button>
            </div>
            <input
                className='btn btn-warning mt-3 fw-bold'
                type="reset"
                value="Limpiar campos"
            />
        </form>
    )
}
