import React from 'react';
import styled from 'styled-components';


export default function Container(props) {
    return (
        <Main>
            {props.children}
        </Main>
    )
}

const Main = styled.main`
    padding: 90px 0px 0px 0px;
    width: 99%;
    margin: auto;
`;
