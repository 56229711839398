import React from 'react';
import AgregarCliente from '../components/AgregarCliente';
import ClienteQuery from '../components/ClienteQuery';
import Container from '../components/layout/Container';

export default function Cliente() {
    return (
        <Container>
            <h3 className='text-center fw-bold'>Cliente</h3>
            <AgregarCliente />
            <div className='mt-3'>
                <ClienteQuery />
            </div>
        </Container>
    )
}
