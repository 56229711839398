import React from 'react';
import styled from 'styled-components';
import firebase from '../lib';
import useValidacion from '../hooks/useValidacion';
import validarIniciarSesion from '../validation/validarIniciarSesion';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

const STATE_INICIAL = {
    email: '',
    password: ''
}

export default function InicioSesion() {
    let history = useHistory();
    const { valores, errores, handleChange, handleSubmit } = useValidacion(STATE_INICIAL, validarIniciarSesion, iniciarSesion);
    const { email, password } = valores;

    async function iniciarSesion() {
        try {
            await firebase.inicioSesion(email, password);
            toast((t) => (
                <span className='fw-bold'>Bienvenido de vuelta!</span>
            ), {
                style: {
                    border: '3px solid #E5097F',
                    padding: '16px',
                }
            })
            history.push('/');
        } catch (error) {
            console.log(error.message);
            toast.error((t) => (
                <span className='fw-bold'>El correo o la contraseña no son validos</span>
            ))
        }
    }

    return (
        <Container>
            <FormContainer>
                <Img src="/logoText.webp" alt="AMA Publicidad" />
                <Form
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className="form-floating mb-3">
                        <input
                            type="email"
                            className="form-control fw-bold"
                            id="email"
                            placeholder="name@example.com"
                            name="email"
                            value={email}
                            onChange={handleChange}
                        />
                        <label htmlFor="email">Correo</label>
                        {errores.email && <Error>{errores.email}</Error>}
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            className="form-control fw-bold"
                            id="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                        />
                        <label htmlFor="password">Contraseña</label>
                        {errores.password && <Error>{errores.password}</Error>}
                    </div>
                    <Submit type='submit'>Iniciar sesión</Submit>
                </Form>
            </FormContainer>
        </Container>
    )
}

const Container = styled.main`
    width: 100%;
    height: 100vh;
    /* background-image: linear-gradient(to top, #09203f 0%, #537895 100%); */
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormContainer = styled.div`
    text-align: center;
    width: 500px;
    @media (max-width: 768px) {
        width: 90%
    }
`;

const Img = styled.img`
    width: 150px;
    padding-bottom: 20px;
`;

const Form = styled.form`
    width: 80%;
    margin: auto;
    @media (max-width: 768px) {
        width: 99%
    }
`;

const Submit = styled.button`
    padding: 5px;
    width: 100%;
    height: 58px;
    border: none;
    border-radius: 5px;
    background: #E5097F;
    color: white;
    font-weight: 700;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    &:hover {
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px, rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        color: black;
        background:  #ffee00;
    }
`;

const Error = styled.span`
    display: block;
    background-color: red;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    padding: 2px 2px;
    margin-top: 3px;
    margin-bottom: 3px;
`;