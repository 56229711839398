import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CrmFinder from "../api/CrmFinder";
import TrabajoCliente from "./maquinas-query/TrabajoCliente";
import Container from './layout/Container';

const ClienteTrabajo = (props) => {
    const { id } = useParams();
    const [trabajos, setTrabajos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await CrmFinder.get(`/clientes/trabajos/${id}`);
                setTrabajos(response.data.cliente);
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Link
                className="btn btn-outline-dark fw-bold"
                aria-current="page"
                to="/clientes"
            >
                Volver a los clientes
            </Link>
            {
                trabajos.length === 0 ?
                    <>
                        <h5 className='text-center fw-bold pt-5 text-muted'>El cliente no tiene trabajos asociados</h5>
                    </>
                    :
                    (
                        <div className='mt-2'>
                            <TrabajoCliente clientes={trabajos} />
                        </div>
                    )
            }
        </Container>
    );
};

export default ClienteTrabajo;