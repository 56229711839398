import React, { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import CrmFinder from '../api/CrmFinder';
import { CrmContext } from '../context/CrmContext';

export default function ClienteQuery(props) {
    const { clientes, setClientes } = useContext(CrmContext);
    let history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            const fetchData = async () => {
                try {
                    const response = await CrmFinder.get('/clientes');
                    setClientes(response.data.cliente)
                } catch (e) {
                    console.log(e);
                }
            }
            fetchData();
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Estas seguro?")) {
            try {
                // eslint-disable-next-line
                const response = await CrmFinder.delete(`/clientes/${id}`);
                setClientes(clientes.filter(cliente => {
                    return cliente.id !== id;
                }));
                toast((t) => (
                    <span className='fw-bold'>Cliente eliminado</span>
                ), { icon: '🗑️' });
            } catch (error) {
                console.log(error);
                toast.error((t) => (
                    <span className='fw-bold'>No se puede eliminar ya que tiene uno o más trabajos asociados</span>
                ));
            }
        } else {
            toast((t) => (
                <span className='fw-bold'>No se eliminó el cliente</span>
            ), { icon: '❗' });
        }
    }

    const handleUpdate = id => {
        history.push(`/clientes/${id}/editar`);
    }

    const handleSelect = id => {
        history.push(`/clientes/trabajos/${id}`);
    }

    return (
        <>
            {
                clientes.length === 0 ? (
                    <>
                        <h5 className='text-center fw-bold pt-5 text-muted'>No hay clientes registrados</h5>
                    </>
                ) : (
                    <div className="list-group table-responsive container-sm">
                        <table className="table table-striped table-hover table-bordered border-secondary table-sm align-middle">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Correo</th>
                                    <th scope="col">Nota</th>
                                    <th scope="col" className='text-center'>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientes && clientes.map((cliente, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{cliente.name}</td>
                                                <td>{cliente.email}</td>
                                                <td>{cliente.quotes}</td>
                                                <td className='text-center' style={{ width: 300 }}>
                                                    <button
                                                        className='btn btn-dark btn-sm fw-bolder m-1'
                                                        onClick={() => handleSelect(cliente.id)}
                                                    ><i className="bi bi-view-list"></i> Trabajos</button>
                                                    <button
                                                        className='btn btn-primary btn-sm fw-bolder m-1'
                                                        onClick={() => handleUpdate(cliente.id)}
                                                    ><i className="bi bi-pencil-square"></i> Editar</button>
                                                    <button
                                                        className='btn btn-danger btn-sm fw-bolder m-1'
                                                        onClick={() => handleDelete(cliente.id)}
                                                    ><i className="bi bi-trash"></i> Eliminar</button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                )
            }
        </>
    )
}
