import React from 'react';
import firebase, { FirebaseContext } from './lib';
import useAutenticacion from './hooks/useAutenticacion';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { CrmContextProvider } from './context/CrmContext';
import Inicio from './routes/Inicio';
import Trabajos from './routes/Trabajos';
import Cliente from './routes/Cliente';
import EditarCliente from './components/EditarCliente';
import { Toaster } from 'react-hot-toast';
import ClienteTrabajo from './components/ClienteTrabajo';
import Archivados from './routes/Archivados';
import EditarTrabajo from './components/EditarTrabajo';
import Correo from './components/Correo';
import Buscar from './routes/Buscar';
import InicioSesion from './routes/InicioSesion';
import NavBar from './components/layout/NavBar';
import PaginaNoEncontrada from './routes/404';
import RegistrarCuenta from './routes/RegitrarCuenta';

function App() {
  const usuario = useAutenticacion();

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        usuario
      }}
    >
      <CrmContextProvider>
        <Router>
          {
            usuario ? (
              <NavBar />
            ) : (
              null
            )
          }
          <Switch>
            <Route exact path='/iniciar-sesion' component={props => !usuario ? <InicioSesion {...props} /> : <Redirect to='/' />} />
            <Route exact path='/registrar' render={props => usuario ? <RegistrarCuenta {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/' render={props => usuario ? <Inicio {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/trabajos' render={props => usuario ? <Trabajos {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/trabajos/:id/editar' render={props => usuario ? <EditarTrabajo {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/clientes' render={props => usuario ? <Cliente {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/clientes/:id/editar' component={EditarCliente} render={props => usuario ? <EditarCliente {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/clientes/trabajos/:id' render={props => usuario ? <ClienteTrabajo {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/archivados' render={props => usuario ? <Archivados {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/enviar-correo/:id' render={props => usuario ? <Correo {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route exact path='/buscar' render={props => usuario ? <Buscar {...props} /> : <Redirect to='/iniciar-sesion' />} />
            <Route path='*' component={PaginaNoEncontrada} />
          </Switch>
          <Toaster />
        </Router>
      </CrmContextProvider >
    </FirebaseContext.Provider>
  );
}

export default App;
