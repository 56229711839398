import React, { useContext, useEffect } from 'react';
import CrmFinder from '../api/CrmFinder';
import { CrmContext } from '../context/CrmContext';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function AgregarTrabajo() {
    const { agregarTrabajo, clientes, setClientes, maquina, setMaquina } = useContext(CrmContext);
    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    useEffect(() => {
        const fetchCliente = async () => {
            try {
                const clientes = await CrmFinder.get('/agregar-trabajo/cliente');
                setClientes(clientes.data.cliente);
            } catch (e) {
                console.log(e);
            }
        }
        fetchCliente();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchCliente = async () => {
            try {
                const maquina = await CrmFinder.get('/agregar-trabajo/maquina');
                setMaquina(maquina.data.maquina);
            } catch (e) {
                console.log(e);
            }
        }
        fetchCliente();
        // eslint-disable-next-line
    }, []);

    const guardarTrabajo = async ({ cliente_id, material, resolucion, terminado, maquina_id, cantidad, tamaño, referencia, remision, observacion }) => {
        try {
            const response = await CrmFinder.post("/agregar-trabajo", {
                cliente_id,
                material,
                resolucion,
                terminado,
                maquina_id,
                cantidad,
                tamaño,
                referencia,
                remision,
                observacion
            });
            agregarTrabajo(response.data.trabajo);
            toast.success((t) => (
                <span className='fw-bold'>Se creo el nuevo trabajo</span>
            ));
        } catch (error) {
            console.log(error);
            toast.error((t) => (
                <span className='fw-bold'>No se pudo crear el nuevo trabajo</span>
            ));
        }
    }

    return (
        <form
            onSubmit={handleSubmit(guardarTrabajo)}
            className='container-sm pt-3'
            autoComplete='on'
        >
            <div className='row mb-3'>
                <div className='col'>
                    <div className='form-floating'>
                        <select
                            name="clientes"
                            id="clientes"
                            className='form-select fw-bold'
                            defaultValue={'DEFAULT'}
                            {...register('cliente_id', { required: true })}
                        >
                            <option disabled value='DEFAULT'>--Seleccionar--</option>
                            {
                                clientes.map(cliente => {
                                    const { id, name } = cliente;
                                    return (
                                        <option key={id} value={id}>{name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="clientes">Cliente</label>
                    </div>
                </div>
                <div className='col'>
                    <div className='form-floating'>
                        <select
                            name="maquina"
                            id="maquina"
                            className='form-select fw-bold'
                            defaultValue={'DEFAULT'}
                            {...register('maquina_id', { required: true })}
                        >
                            <option disabled value='DEFAULT'>--Seleccionar--</option>
                            {
                                maquina.map(maquinas => {
                                    const { id, name } = maquinas;
                                    return (
                                        <option key={id} value={id}>{name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="maquina">Maquina</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col'>
                    <div className="form-floating mb-3 col">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="material"
                            placeholder="material"
                            {...register('material', { maxLength: 100 })}
                        />
                        <label htmlFor="material">Material</label>
                        {errors.referencia && <span className='text-danger'>Máximo 100 caracteres!</span>}
                    </div>
                </div>
                <div className='col'>
                    <div className="form-floating mb-3 col">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="referencia"
                            placeholder="referencia"
                            name="referencia"
                            {...register('referencia', { maxLength: 100 })}
                        />
                        <label htmlFor="referencia">Referencia</label>
                        {errors.referencia && <span className='text-danger'>Máximo 100 caracteres!</span>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col'>
                    <div className="form-floating mb-3 col">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="resolucion"
                            placeholder="resolucion"
                            {...register('resolucion', { maxLength: 100 })}
                        />
                        <label htmlFor="resolucion">Resolución</label>
                        {errors.referencia && <span className='text-danger'>Máximo 100 caracteres!</span>}
                    </div>
                </div>
                <div className='col'>
                    <div className="form-floating mb-3 col">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="terminado"
                            placeholder="terminado"
                            {...register('terminado', { maxLength: 200 })}
                        />
                        <label htmlFor="terminado">Terminado</label>
                        {errors.referencia && <span className='text-danger'>Máximo 200 caracteres!</span>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="cantidad"
                            placeholder="cantidad"
                            {...register('cantidad', { maxLength: 100 })}
                        />
                        <label htmlFor="cantidad">Cantidad</label>
                        {errors.referencia && <span className='text-danger'>Máximo 100 caracteres!</span>}
                    </div>
                </div>
                <div className="col">
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="tamaño"
                            placeholder="tamaño"
                            {...register('tamaño', { maxLength: 200 })}
                        />
                        <label htmlFor="tamaño">Tamaño</label>
                        {errors.referencia && <span className='text-danger'>Máximo 200 caracteres!</span>}
                    </div>

                </div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control fw-bold"
                    id="floatingInput"
                    placeholder="Remisión"
                    {...register('remision', { maxLength: 50 })}
                />
                <label htmlFor="floatingInput">Remisión</label>
                {errors.remision && <span className='text-danger'>Máximo 50 caracteres!</span>}
            </div>
            <div className="form-floating mb-3">
                <textarea
                    className="form-control fw-bold"
                    placeholder="Observacion"
                    id="observacion"
                    style={{ height: '100px' }}
                    {...register('observacion', { maxLength: 200 })}
                ></textarea>
                <label htmlFor="observacion">Observación</label>
                {errors.observacion && <span className='text-danger'>Máximo 200 caracteres!</span>}
            </div>
            <div className="col-12">
                <button
                    className="btn btn-primary fw-bold"
                    type="submit"
                    disabled={!isDirty || !isValid}
                >Guardar</button>
            </div>
        </form>
    )
}
