import React, { createContext, useState } from 'react';

export const CrmContext = createContext();

export const CrmContextProvider = props => {
    const [trabajos, setTrabajos] = useState([]);
    const [trabajosDos, setTrabajosDos] = useState([]);
    const [trabajosTres, setTrabajosTres] = useState([]);
    const [trabajosCuatro, setTrabajosCuatro] = useState([]);
    const [trabajosCinco, setTrabajosCinco] = useState([]);
    const [archivado, setArchivado] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [maquina, setMaquina] = useState([]);

    const agregarTrabajo = (trabajo) => {
        setTrabajos([...trabajos, trabajo]);
    }
    
    const agregarCliente = (cliente) => {
        setClientes([...clientes, cliente]);
    }

    const agregarMaquina = (maquinas) => {
        setMaquina([...maquina, maquinas]);
    }

    return (
        <CrmContext.Provider
            value={{
                trabajos,
                setTrabajos,
                trabajosDos,
                setTrabajosDos,
                trabajosTres,
                setTrabajosTres,
                trabajosCuatro,
                setTrabajosCuatro,
                trabajosCinco,
                setTrabajosCinco,
                archivado,
                setArchivado,
                clientes,
                setClientes,
                maquina,
                setMaquina,
                agregarTrabajo,
                agregarCliente,
                agregarMaquina,
            }}
        >
            {props.children}
        </CrmContext.Provider>
    )
}
