import React from 'react';
import Container from '../components/layout/Container';
import ArchivadoQuery from '../components/maquinas-query/ArchivadoQuery';

export default function Archivados() {
    return (
        <Container>
            <h3 className='text-center fw-bold'>Trabajos archivados</h3>
            <div className='mt-4'>
                <ArchivadoQuery />
            </div>
        </Container>
    )
}
