import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import CrmFinder from '../api/CrmFinder';
import Container from './layout/Container';

const EditarCliente = (props) => {
    const { id } = useParams();
    let history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [quotes, setQuotes] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await CrmFinder.get(`/clientes/${id}`);
            setName(response.data.data.clientes.name);
            setEmail(response.data.data.clientes.email);
            setQuotes(response.data.data.clientes.quotes);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // eslint-disable-next-line
            const actualizarCliente = await CrmFinder.put(`/clientes/${id}`, {
                name,
                email,
                quotes
            });
            toast.success((t) => (
                <span className='fw-bold'>El trabajo se actualizó</span>
            ));
            history.push('/clientes');
        } catch (error) {
            console.log(error);
            toast((t) => (
                <span className='fw-bold'>No se realizaron los cambios</span>
            ), { icon: '❗' });
        }
    }

    return (
        <Container>
            <div className='container-sm'>
                <Link
                    className="btn btn-outline-dark fw-bold"
                    aria-current="page"
                    to="/clientes"
                >
                    Volver a los clientes
                </Link>
                <h2 className='text-center mb-3 fw-normal'>Cliente: <span className='fw-bold'>{name}</span></h2>
                <form action="" className="mx-auto align-items-center">
                    <div className="row">
                        <div className="col">
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="floatingInput"
                                    placeholder="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Nombre</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="floatingInput"
                                    placeholder="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Correo electrónico</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="floatingInput"
                            placeholder="notas"
                            value={quotes}
                            onChange={(e) => setQuotes(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Notas</label>
                    </div>
                    <button
                        className="btn btn-primary fw-bold"
                        type="submit"
                        onClick={handleSubmit}
                    >Guardar</button>
                </form>
            </div>
        </Container>
    );
}

export default EditarCliente;