import React, { useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FirebaseContext } from '../../lib';

export default function NavBar() {
    let location = useLocation();
    const { usuario, firebase } = useContext(FirebaseContext);

    return (
        <>
            {
                location.pathname === '/iniciar-sesion' || location.pathname === '/PaginaNoEncontrada' ?
                    null
                    : (

                        <nav className="navbar fixed-top navbar-expand-lg navbar-light shadow-sm" style={{ background: '#fff' }}>
                            <div className="container-fluid">
                                <NavLink
                                    exact
                                    to="/"
                                    className="text-decoration-none navbar-brand me-4"
                                >
                                    <img src="/logo.webp" alt="logo" style={{ width: 100 }} />
                                </NavLink>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item me-2 me-2">
                                            <NavLink
                                                exact
                                                activeClassName='active fw-bold'
                                                className='nav-link'
                                                aria-current="page"
                                                to="/"
                                            >
                                                <i className="bi bi-house-fill"></i> Inicio
                                            </NavLink>
                                        </li>
                                        <li className="nav-item me-2">
                                            <NavLink
                                                exact
                                                activeClassName='active fw-bold'
                                                className='nav-link'
                                                aria-current="page"
                                                to="/trabajos"
                                            >
                                                <i className="bi bi-pen-fill"></i> Trabajos
                                            </NavLink>
                                        </li>
                                        <li className="nav-item me-2">
                                            <NavLink
                                                exact
                                                activeClassName='active fw-bold'
                                                className='nav-link'
                                                aria-current="page"
                                                to="/clientes"
                                            >
                                                <i className="bi bi-people-fill"></i> Clientes
                                            </NavLink>
                                        </li>
                                        <li className="nav-item me-2">
                                            <NavLink
                                                exact
                                                activeClassName='active fw-bold'
                                                className='nav-link'
                                                aria-current="page"
                                                to="/archivados"
                                            >
                                                <i className="bi bi-archive-fill"></i> Archivados
                                            </NavLink>
                                        </li>
                                        <li className="nav-item me-2">
                                            <NavLink
                                                exact
                                                activeClassName='active fw-bold'
                                                className='nav-link'
                                                aria-current="page"
                                                to="/buscar"
                                            >
                                                <i className="bi bi-search"></i> Buscar
                                            </NavLink>
                                        </li>
                                        {
                                            usuario.uid === 'O1fihL2cURMEOONdnQcik5OsYx83' || usuario.uid === 'zjBDap1bvYQ4YWD2wE8r2M6gxAz1' ? (
                                                <li className="nav-item me-2">
                                                    <NavLink
                                                        exact
                                                        activeClassName='active fw-bold'
                                                        className='nav-link'
                                                        aria-current="page"
                                                        to="/registrar"
                                                    >
                                                        <i className="bi bi-person-plus-fill"></i> Crear cuenta
                                                    </NavLink>
                                                </li>
                                            ) : (
                                                null
                                            )
                                        }
                                    </ul>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-light btn-sm fw-bold dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                            {usuario.displayName}
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-lg-end text-center">
                                            <li>
                                                <Link to='/iniciar-sesion'>
                                                    <button
                                                        className="btn btn-sm fw-bolder text-danger"
                                                        onClick={() => firebase.cerrarSesion()}
                                                    >Cerrar sesión <i className="bi bi-box-arrow-right"></i></button>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    )
            }
        </>
    )
}
