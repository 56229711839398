import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import useValidacion from '../hooks/useValidacion';
import validarRegistrarCuenta from '../validation/validarRegistrarCuenta';
import firebase from '../lib';
import toast from 'react-hot-toast';
import { useHistory } from "react-router-dom";
import { FirebaseContext } from '../lib';

const STATE_INICIAL = {
    nombre: '',
    email: '',
    password: ''
}

export default function RegistrarCuenta() {
    const { usuario } = useContext(FirebaseContext);
    let history = useHistory();
    const { valores, errores, handleChange, handleSubmit } = useValidacion(STATE_INICIAL, validarRegistrarCuenta, registrarCuenta);
    const { nombre, email, password } = valores;
    const [error, setError] = useState(false);

    async function registrarCuenta() {
        try {
            await firebase.registrar(nombre, email, password);
            toast.success((t) => (
                <span className='fw-bold'>Se creo el usuario</span>
            ));
        } catch (error) {
            console.log(error.message);
            setError('El correo electrónico ya esta registrado');
        }

    }

    return (
        <Container>
            {
                usuario.uid === 'zjBDap1bvYQ4YWD2wE8r2M6gxAz1' || usuario.uid === 'O1fihL2cURMEOONdnQcik5OsYx83' ? (

                    <FormContainer>
                        <Img src="/logoText.webp" alt="AMA Publicidad" />
                        <Form
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="nombre"
                                    placeholder="Tu nombre"
                                    name="nombre"
                                    value={nombre}
                                    onChange={handleChange}
                                />
                                <label htmlFor="nombre">Nombre</label>
                                {errores.nombre && <Error>{errores.nombre}</Error>}
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control fw-bold"
                                    id="email"
                                    placeholder="name@example.com"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                />
                                <label htmlFor="email">Correo</label>
                                {errores.email && <Error>{errores.email}</Error>}
                                {error && <Error>{error}</Error>}
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control fw-bold"
                                    id="password"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                />
                                <label htmlFor="password">Contraseña</label>
                                {errores.password && <Error>{errores.password}</Error>}
                            </div>
                            <Submit type='submit'>Registrar cuenta</Submit>
                        </Form>
                    </FormContainer>
                ) : (
                    history.push('/')
                )
            }
        </Container>
    )
}

const Container = styled.main`
    width: 100%;
    height: 100vh;
    /* background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%); */
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormContainer = styled.div`
    text-align: center;
    width: 500px;
    @media (max-width: 768px) {
        width: 90%
    }
`;

const Img = styled.img`
    width: 150px;
    padding-bottom: 20px;
`;

const Form = styled.form`
    width: 80%;
    margin: auto;
    @media (max-width: 768px) {
        width: 99%
    }
`;

const Submit = styled.button`
    padding: 5px;
    width: 100%;
    height: 58px;
    border: none;
    border-radius: 5px;
    background: #ff008c;
    color: white;
    font-weight: 700;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    &:hover {
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px, rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        background:  #0051ff;
    }
`;

const Error = styled.span`
    display: block;
    background-color: red;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
`;