import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { Link, useParams, useHistory } from 'react-router-dom';
import CrmFinder from '../api/CrmFinder';
import toast from 'react-hot-toast';
import Container from './layout/Container';

export default function Correo() {
    const { id } = useParams();
    let history = useHistory();
    const [cliente, setCliente] = useState('');
    const [correo, setCorreo] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [material, setMaterial] = useState('');
    const [tamaño, setTamaño] = useState('');
    const [terminado, setTerminado] = useState('');
    const [referencia, setReferencia] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await CrmFinder.get(`/correo/${id}`);
            setCliente(response.data.data.correo.cliente);
            setCorreo(response.data.data.correo.correo);
            setCantidad(response.data.data.correo.cantidad);
            setMaterial(response.data.data.correo.material);
            setTamaño(response.data.data.correo.tamaño);
            setTerminado(response.data.data.correo.terminado);
            setReferencia(response.data.data.correo.referencia);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_n5ud3iv', 'template_ofynb14', e.target, 'user_MV5u38AqlIxZkjcOPEPhj')
            .then((result) => {
                console.log(result.text);
                toast.success((t) => (
                    <span className='fw-bold'>Correo enviado</span>
                ));
                history.push('/');
            }, (error) => {
                console.log(error.text);
                toast.error((t) => (
                    <span className='fw-bold'>El correo no se pudo enviar</span>
                ));
            });
    }

    return (
        <Container>
            <div className='container-sm'>
                <Link
                    className="btn btn-outline-dark fw-bold"
                    aria-current="page"
                    to="/"
                >
                    Volver a los trabajos
                </Link>
                <h2 className='text-center mb-3 fw-normal'>Correo para <span className='fw-bold text-uppercase'>{cliente}</span></h2>
                <form onSubmit={sendEmail}>
                    <div className='row'>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="email"
                                    placeholder="email"
                                    defaultValue={correo}
                                    name='email_cliente'
                                />
                                <label htmlFor="email">Correo</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="cliente"
                                    placeholder="cliente"
                                    defaultValue={cliente}
                                    name='to_name'
                                />
                                <label htmlFor="cliente">Cliente</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-floating mb-3 col">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="referencia"
                            placeholder="referencia"
                            defaultValue={referencia}
                            name='referencia'
                        />
                        <label htmlFor="referencia">Referencia</label>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="cantidad"
                                    placeholder="cantidad"
                                    defaultValue={cantidad}
                                    name='cantidad'
                                />
                                <label htmlFor="cantidad">Cantidad</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="material"
                                    placeholder="material"
                                    defaultValue={material}
                                    name='material'
                                />
                                <label htmlFor="material">Material</label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="tamaño"
                                    placeholder="tamaño"
                                    defaultValue={tamaño}
                                    name='tamano'
                                />
                                <label htmlFor="tamaño">Tamaño</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3 col">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="terminado"
                                    placeholder="terminado"
                                    defaultValue={terminado}
                                    name='terminado'
                                />
                                <label htmlFor="terminado">Terminado</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea
                            className="form-control fw-bold"
                            placeholder="Observacion"
                            id="observacion"
                            style={{ height: '100px' }}
                            name='message'
                        ></textarea>
                        <label htmlFor="observacion">Mensaje adicional</label>
                    </div>
                    <input type="submit" value="Enviar" className='btn btn-primary fw-bold' />
                </form>
            </div>
        </Container>
    )
}
