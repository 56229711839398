import React, { useContext, useEffect, useState } from 'react';
import CrmFinder from '../../api/CrmFinder';
import { CrmContext } from '../../context/CrmContext';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function MaquinaFour() {
    const { trabajosCuatro, setTrabajosCuatro } = useContext(CrmContext);
    const [facturado, setFacturado] = useState(false);
    const [impreso, setImpreso] = useState(false);
    const [finalizado, setFinalizado] = useState(false);
    const [entregado, setEntregado] = useState(false);
    const [archivado, setArchivado] = useState(false);
    let history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            const fetchData = async () => {
                try {
                    const response = await CrmFinder.get('/maquina-cuatro');
                    setTrabajosCuatro(response.data.maquinaFour)
                } catch (e) {
                    console.log(e);
                }
            }
            fetchData();
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const handleUpdate = id => {
        history.push(`/trabajos/${id}/editar`);
    }

    const handleEmail = id => {
        history.push(`/enviar-correo/${id}`);
    }

    const handleFacturado = async id => {
        setFacturado(value => !value);
        try {
            // eslint-disable-next-line
            const actualizarFactura = await CrmFinder.put(`trabajo-facturado/${id}`, {
                facturado
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleImpreso = async id => {
        setImpreso(value => !value);
        try {
            // eslint-disable-next-line
            const actualizarImpreso = await CrmFinder.put(`trabajo-impreso/${id}`, {
                impreso
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleFinalizado = async id => {
        setFinalizado(value => !value);
        try {
            // eslint-disable-next-line
            const actualizarFinalizado = await CrmFinder.put(`trabajo-finalizado/${id}`, {
                finalizado
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleEntregado = async id => {
        setEntregado(value => !value);
        try {
            // eslint-disable-next-line
            const actualizarEntregado = await CrmFinder.put(`trabajo-entregado/${id}`, {
                entregado
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleArchivado = async id => {
        setArchivado(value => !value);
        try {
            // eslint-disable-next-line
            const actualizarArchivado = await CrmFinder.put(`trabajo-archivado/${id}`, {
                archivado
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {
                trabajosCuatro.length === 0 ? (
                    <>
                        <p className='text-center fw-bold pt-5'>CORTE LÁSER no tiene trabajos activos!</p>
                    </>
                ) : (
                    <>
                        <div className="list-group table-responsive">
                            <table className="table table-striped table-hover table-bordered border-secondary caption-top table-sm align-middle" id='corteLaser'>
                                <caption>
                                    <h3 className='fw-bold m-0 p-0 text-dark'>CORTE LÁSER</h3>
                                    <ReactHTMLTableToExcel
                                        id='exportarExcel'
                                        className='btn btn-success btn-sm fw-bold float-end'
                                        table='corteLaser'
                                        filename='corteLaser'
                                        sheet='pagina 1'
                                        buttonText='Exportar'
                                    />
                                </caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Maquina</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Fact</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Referencia</th>
                                        <th scope="col">Tamaño</th>
                                        <th scope="col">Resolución</th>
                                        <th scope="col">Terminado</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Impreso</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Finalizado</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Entregado</th>
                                        <th scope="col">Remisión</th>
                                        <th scope="col">Observaciones</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Archivado</th>
                                        <th scope='col' className='text-center' style={{ fontSize: 12 }}>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        trabajosCuatro && trabajosCuatro.map((trabajo, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{(new Date(trabajo.posting_date)).toLocaleDateString()}</td>
                                                    <td>{trabajo.cliente}</td>
                                                    <td>{trabajo.maquina}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            className={trabajo.facturado ? "btn btn-success btn-sm fw-bold" : "btn btn-danger btn-sm fw-bold"}
                                                            name="facturado"
                                                            onClick={() => handleFacturado(trabajo.id)}
                                                        >
                                                            {trabajo.facturado ? "Si" : "No"}
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>{trabajo.cantidad}</td>
                                                    <td>{trabajo.material}</td>
                                                    <td>{trabajo.referencia}</td>
                                                    <td>{trabajo.tamaño}</td>
                                                    <td>{trabajo.resolucion}</td>
                                                    <td>{trabajo.terminado}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            className={trabajo.impreso ? "btn btn-success btn-sm fw-bold" : "btn btn-danger btn-sm fw-bold"}
                                                            name="impreso"
                                                            onClick={() => handleImpreso(trabajo.id)}
                                                        >
                                                            {trabajo.impreso ? "Si" : "No"}
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>
                                                        <button
                                                            className={trabajo.finalizado ? "btn btn-success btn-sm fw-bold" : "btn btn-danger btn-sm fw-bold"}
                                                            name="finalizado"
                                                            onClick={() => handleFinalizado(trabajo.id)}
                                                        >
                                                            {trabajo.finalizado ? "Si" : "No"}
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>
                                                        <button
                                                            className={trabajo.entregado ? "btn btn-success btn-sm fw-bold" : "btn btn-danger btn-sm fw-bold"}
                                                            name="entregado"
                                                            onClick={() => handleEntregado(trabajo.id)}
                                                        >
                                                            {trabajo.entregado ? "Si" : "No"}
                                                        </button>
                                                    </td>
                                                    <td>{trabajo.remision}</td>
                                                    <td>{trabajo.observacion}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            className={trabajo.archivado ? "btn btn-success btn-sm fw-bold" : "btn btn-danger btn-sm fw-bold"}
                                                            name="archivado"
                                                            onClick={() => handleArchivado(trabajo.id)}
                                                        >
                                                            {trabajo.archivado ? "Si" : "No"}
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>
                                                        <button
                                                            className='btn btn-primary btn-sm m-1'
                                                            aria-label="Center Align"
                                                            onClick={() => handleUpdate(trabajo.id)}
                                                        ><i className="bi bi-pencil-square"></i></button>
                                                        <button
                                                            className='btn btn-warning btn-sm m-1'
                                                            aria-label="Center Align"
                                                            onClick={() => handleEmail(trabajo.id)}
                                                        >
                                                            <i className="bi bi-envelope-fill"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            }
        </>
    )
}
