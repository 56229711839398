import React, { useState } from 'react';
import CrmFinder from '../api/CrmFinder';
import { useHistory } from 'react-router-dom';
import Container from '../components/layout/Container';

export default function Buscar() {
    const [text, setText] = useState('');
    const [trabajos, setTrabajos] = useState([]);
    let history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await CrmFinder.get(`/buscar/?text=${text}`);
            setTrabajos(response.data.buscar);
        } catch (e) {
            console.log(e);
        }
    }

    const handleUpdate = id => {
        history.push(`/trabajos/${id}/editar`);
    }

    const handleEmail = id => {
        history.push(`/enviar-correo/${id}`);
    }

    return (
        <Container>
            <form className="d-flex pt-4" onSubmit={handleSubmit} style={{ width: 380, margin: 'auto' }}>
                <input
                    className="form-control me-2"
                    type="search" placeholder="Cliente, referencia o remisión"
                    aria-label="Buscar"
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                <button className="btn btn-outline-secondary" type="submit"> <i className="bi bi-search"></i></button>
            </form>
            <div className='mt-5'>
                {
                    trabajos.length === 0 ? (
                        <>
                        <h5 className='text-center fw-bold pt-5 text-muted'>No se ha introducido ninguna palabra clave...</h5>
                    </>
                    )
                        :
                        (
                            <div className="list-group table-responsive">
                                <table className="table table-striped table-hover table-bordered border-secondary table-sm align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Maquina</th>
                                            <th scope="col" className='text-center'>Fact</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Material</th>
                                            <th scope="col">Referencia</th>
                                            <th scope="col">Tamaño</th>
                                            <th scope="col">Resolución</th>
                                            <th scope="col">Terminado</th>
                                            <th scope="col" className='text-center'>Impreso</th>
                                            <th scope="col" className='text-center'>Finalizado</th>
                                            <th scope="col" className='text-center'>Entregado</th>
                                            <th scope="col">Remisión</th>
                                            <th scope="col">Observaciones</th>
                                            <th scope="col" className='text-center'>Archivado</th>
                                            <th scope='col' className='text-center'>Acciones</th>
                                        </tr>
                                    </thead >
                                    <tbody>
                                        {
                                            trabajos && trabajos.map((trabajo, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{(new Date(trabajo.posting_date)).toISOString().slice(0, 10)}</td>
                                                        <td>{trabajo.cliente}</td>
                                                        <td>{trabajo.maquina}</td>
                                                        <td className='text-center'>
                                                            <span
                                                                className={trabajo.facturado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                            >
                                                                {
                                                                    trabajo.facturado ? 'Si' : 'No'
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>{trabajo.cantidad}</td>
                                                        <td>{trabajo.material}</td>
                                                        <td>{trabajo.referencia}</td>
                                                        <td>{trabajo.tamaño}</td>
                                                        <td>{trabajo.resolucion}</td>
                                                        <td>{trabajo.terminado}</td>
                                                        <td className='text-center'>
                                                            <span
                                                                className={trabajo.impreso ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                            >
                                                                {
                                                                    trabajo.impreso ? 'Si' : 'No'
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span
                                                                className={trabajo.finalizado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                            >
                                                                {
                                                                    trabajo.finalizado ? 'Si' : 'No'
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span
                                                                className={trabajo.entregado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                            >
                                                                {
                                                                    trabajo.entregado ? 'Si' : 'No'
                                                                }
                                                            </span>
                                                        </td>
                                                        <td>{trabajo.remision}</td>
                                                        <td>{trabajo.observacion}</td>
                                                        <td className='text-center'>
                                                            <span
                                                                className={trabajo.archivado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                            >
                                                                {
                                                                    trabajo.archivado ? 'Si' : 'No'
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <button
                                                                className='btn btn-primary btn-sm m-1'
                                                                onClick={() => handleUpdate(trabajo.id)}
                                                            ><i className="bi bi-pencil-square"></i></button>
                                                            <button
                                                                className='btn btn-warning btn-sm m-1'
                                                                onClick={() => handleEmail(trabajo.id)}
                                                            >
                                                                <i className="bi bi-envelope-fill"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table >
                            </div >
                        )
                }
            </div >
        </Container>
    )
}
