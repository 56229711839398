import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import firebaseConfig from './config';

class Firebase {
    constructor() {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        this.auth = firebase.auth();
    }

    async registrar(nombre, email, password) {
        const nuevoUsuario = await this.auth.createUserWithEmailAndPassword(email, password);

        return await nuevoUsuario.user.updateProfile({
            displayName: nombre
        });
    }

    async inicioSesion(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    async cerrarSesion() {
        await this.auth.signOut();
    }
}

const firebaseApp = new Firebase();
export default firebaseApp;
