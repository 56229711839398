import React from 'react';
import { useHistory } from 'react-router-dom';

const TrabajoCliente = ({ clientes }) => {
    let history = useHistory();

    const handleUpdate = id => {
        history.push(`/trabajos/${id}/editar`);
    }

    const handleEmail = id => {
        history.push(`/enviar-correo/${id}`);
    }

    return (
        <div className="list-group table-responsive">
            <table className="table table-striped table-hover table-bordered border-secondary table-sm align-middle">
                <thead>
                    <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Maquina</th>
                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Fact</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Material</th>
                        <th scope="col">Referencia</th>
                        <th scope="col">Tamaño</th>
                        <th scope="col">Resolución</th>
                        <th scope="col">Terminado</th>
                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Impreso</th>
                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Finalizado</th>
                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Entregado</th>
                        <th scope="col">Remisión</th>
                        <th scope="col">Observaciones</th>
                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Archivado</th>
                        <th scope='col' className='text-center' style={{ fontSize: 12 }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        clientes && clientes.map((trabajo, key) => {
                            return (
                                <tr key={key}>
                                    <td>{(new Date(trabajo.posting_date)).toLocaleDateString()}</td>
                                    <td>{trabajo.cliente}</td>
                                    <td>{trabajo.maquina}</td>
                                    <td className='text-center'>
                                        <span
                                            className={trabajo.facturado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                        >
                                            {
                                                trabajo.facturado ? 'Si' : 'No'
                                            }
                                        </span>
                                    </td>
                                    <td className='text-center'>{trabajo.cantidad}</td>
                                    <td>{trabajo.material}</td>
                                    <td>{trabajo.referencia}</td>
                                    <td>{trabajo.tamaño}</td>
                                    <td>{trabajo.resolucion}</td>
                                    <td>{trabajo.terminado}</td>
                                    <td className='text-center'>
                                        <span
                                            className={trabajo.impreso ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                        >
                                            {
                                                trabajo.impreso ? 'Si' : 'No'
                                            }
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <span
                                            className={trabajo.finalizado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                        >
                                            {
                                                trabajo.finalizado ? 'Si' : 'No'
                                            }
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <span
                                            className={trabajo.entregado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                        >
                                            {
                                                trabajo.entregado ? 'Si' : 'No'
                                            }
                                        </span>
                                    </td>
                                    <td>{trabajo.remision}</td>
                                    <td>{trabajo.observacion}</td>
                                    <td className='text-center'>
                                        <span
                                            className={trabajo.archivado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                        >
                                            {
                                                trabajo.archivado ? 'Si' : 'No'
                                            }
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <button
                                            className='btn btn-primary btn-sm m-1'
                                            onClick={() => handleUpdate(trabajo.id)}
                                        ><i className="bi bi-pencil-square"></i></button>
                                        <button
                                            className='btn btn-warning btn-sm m-1'
                                            onClick={() => handleEmail(trabajo.id)}
                                        >
                                            <i className="bi bi-envelope-fill"></i>
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TrabajoCliente;