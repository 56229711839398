import React from 'react';
import AgregarTrabajo from '../components/AgregarTrabajo';
import Container from '../components/layout/Container';
import Maquina from '../components/maquinas-query/Maquinas';

export default function Trabajos() {
    return (
        <Container>
            <h3 className='text-center fw-bold'>Trabajos</h3>
            <AgregarTrabajo />
            <div className='mt-3'>
                <Maquina />
            </div>
        </Container>
    )
}
