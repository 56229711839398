import React from 'react';
import Container from '../components/layout/Container';
import MaquinaFive from '../components/maquinas-query/MaquinaFive';
import MaquinaFour from '../components/maquinas-query/MaquinaFour';
import MaquinaOne from '../components/maquinas-query/MaquinaOne';
import MaquinaThree from '../components/maquinas-query/MaquinaThree';
import MaquinaTwo from '../components/maquinas-query/MaquinaTwo';

export default function Inicio() {
    return (
        <Container>
            <div>
                <MaquinaOne />
            </div>
            <div>
                <MaquinaTwo />
            </div>
            <div>
                <MaquinaThree />
            </div>
            <div>
                <MaquinaFour />
            </div>
            <div>
                <MaquinaFive />
            </div>
        </Container>
    )
}
