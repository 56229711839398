import React, { useContext, useEffect } from 'react';
import CrmFinder from '../../api/CrmFinder';
import { CrmContext } from '../../context/CrmContext';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function Maquina() {
    const { trabajos, setTrabajos } = useContext(CrmContext);
    let history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            const fetchData = async () => {
                try {
                    const response = await CrmFinder.get('/maquina');
                    setTrabajos(response.data.maquina)
                } catch (e) {
                    console.log(e);
                }
            }
            fetchData();
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const handleUpdate = id => {
        history.push(`/trabajos/${id}/editar`);
    }

    const handleDelete = async (id) => {
        if (window.confirm("Estas seguro?")) {
            await CrmFinder.delete(`/trabajo/${id}`);
            setTrabajos(trabajos.filter(trabajo => {
                return trabajo.id !== id;
            }))
            toast((t) => (
                <span className='fw-bold'>Trabajo eliminado</span>
            ), { icon: '🗑️' });
        } else {
            toast((t) => (
                <span className='fw-bold'>No se elimino el trabajo</span>
            ), { icon: '❗' });
        }
    }

    return (
        <>
            {
                trabajos.length === 0 ? (
                    <>
                        <h5 className='text-center fw-bold pt-5 text-muted'>No hay trabajos registrados</h5>
                    </>
                ) : (

                    <div className="list-group table-responsive">
                        <table className="table table-striped table-hover table-bordered border-secondary caption-top table-sm align-middle">
                            <caption>Últimos cinco trabajos</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Maquina</th>
                                    <th scope="col" className='text-center'>Cantidad</th>
                                    <th scope="col">Material</th>
                                    <th scope="col">Referencia</th>
                                    <th scope="col">Tamaño</th>
                                    <th scope="col">Resolución</th>
                                    <th scope="col">Terminado</th>
                                    <th scope="col">Remisión</th>
                                    <th scope="col">Observaciones</th>
                                    <th scope="col" className='text-center'>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    trabajos && trabajos.map((trabajo, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{(new Date(trabajo.posting_date)).toLocaleDateString()}</td>
                                                <td>{trabajo.cliente}</td>
                                                <td>{trabajo.maquina}</td>
                                                <td className='text-center'>{trabajo.cantidad}</td>
                                                <td>{trabajo.material}</td>
                                                <td>{trabajo.referencia}</td>
                                                <td>{trabajo.tamaño}</td>
                                                <td>{trabajo.resolucion}</td>
                                                <td>{trabajo.terminado}</td>
                                                <td>{trabajo.remision}</td>
                                                <td>{trabajo.observacion}</td>
                                                <td className="text-center">
                                                    <button
                                                        className='btn btn-primary btn-sm m-1'
                                                        onClick={() => handleUpdate(trabajo.id)}
                                                        aria-label="Center Align"
                                                    ><i className="bi bi-pencil-square"></i></button>
                                                    <button
                                                        className='btn btn-danger btn-sm m-1'
                                                        title="Eliminar"
                                                        aria-label="Center Align"
                                                        onClick={() => handleDelete(trabajo.id)}
                                                    >
                                                        <i className="bi bi-trash-fill"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                )
            }
        </>
    )
}
