import React, { useContext, useEffect } from 'react';
import CrmFinder from '../../api/CrmFinder';
import { CrmContext } from '../../context/CrmContext';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ArchivadoQuery() {
    const { archivado, setArchivado } = useContext(CrmContext);
    let history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            const fetchData = async () => {
                try {
                    const response = await CrmFinder.get('/archivados');
                    setArchivado(response.data.archivado);
                } catch (e) {
                    console.log(e);
                }
            }
            fetchData();
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const handleUpdate = id => {
        history.push(`/trabajos/${id}/editar`);
    }

    const handleEmail = id => {
        history.push(`/enviar-correo/${id}`);
    }

    const handleDelete = async (id) => {
        if (window.confirm("Estas seguro?")) {
            await CrmFinder.delete(`/trabajo/${id}`);
            setArchivado(archivado.filter(trabajo => {
                return trabajo.id !== id;
            }))
            toast((t) => (
                <span className='fw-bold'>Trabajo eliminado</span>
            ), { icon: '🗑️' });
        } else {
            toast((t) => (
                <span className='fw-bold'>No se eliminó el trabajo</span>
            ), { icon: '❗' });
        }
    }


    return (
        <>
            {
                archivado.length === 0 ? (
                    <>
                        <h5 className='text-center fw-bold pt-5 text-muted'>No hay trabajos archivados</h5>
                    </>
                ) : (
                    <>
                        <div className='mb-3'>
                            <ReactHTMLTableToExcel
                                id='exportarExcel'
                                className='btn btn-success btn-sm fw-bold'
                                table='archivados'
                                filename='archivados'
                                sheet='pagina 1'
                                buttonText='Exportar tabla a Excel'
                            />
                        </div>
                        <div className="list-group table-responsive">
                            <table className="table table-striped table-hover table-bordered border-secondary table-sm align-middle" id='archivados'>
                                <thead>
                                    <tr>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Maquina</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Fact</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Referencia</th>
                                        <th scope="col">Tamaño</th>
                                        <th scope="col">Resolución</th>
                                        <th scope="col">Terminado</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Impreso</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Finalizado</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Entregado</th>
                                        <th scope="col">Remisión</th>
                                        <th scope="col">Observaciones</th>
                                        <th scope="col" className='text-center' style={{ fontSize: 12 }}>Archivado</th>
                                        <th scope='col' className='text-center' style={{ fontSize: 12 }}>Acciones</th>
                                    </tr >
                                </thead >
                                <tbody>
                                    {
                                        archivado && archivado.map((trabajo, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{(new Date(trabajo.posting_date)).toLocaleDateString()}</td>
                                                    <td>{trabajo.cliente}</td>
                                                    <td>{trabajo.maquina}</td>
                                                    <td className='text-center'>
                                                        <span
                                                            className={trabajo.facturado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                        >
                                                            {
                                                                trabajo.facturado ? 'Si' : 'No'
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className='text-center'>{trabajo.cantidad}</td>
                                                    <td>{trabajo.material}</td>
                                                    <td>{trabajo.referencia}</td>
                                                    <td>{trabajo.tamaño}</td>
                                                    <td>{trabajo.resolucion}</td>
                                                    <td>{trabajo.terminado}</td>
                                                    <td className='text-center'>
                                                        <span
                                                            className={trabajo.impreso ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                        >
                                                            {
                                                                trabajo.impreso ? 'Si' : 'No'
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span
                                                            className={trabajo.finalizado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                        >
                                                            {
                                                                trabajo.finalizado ? 'Si' : 'No'
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span
                                                            className={trabajo.entregado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                        >
                                                            {
                                                                trabajo.entregado ? 'Si' : 'No'
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>{trabajo.remision}</td>
                                                    <td>{trabajo.observacion}</td>
                                                    <td className='text-center'>
                                                        <span
                                                            className={trabajo.archivado ? 'text-success fw-bold' : ' text-danger fw-bold'}
                                                        >
                                                            {
                                                                trabajo.archivado ? 'Si' : 'No'
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <button
                                                            className='btn btn-primary btn-sm m-1'
                                                            aria-label="Center Align"
                                                            title="Editar"
                                                            onClick={() => handleUpdate(trabajo.id)}
                                                        ><i className="bi bi-pencil-square"></i></button>
                                                        <button
                                                            className='btn btn-warning btn-sm m-1'
                                                            title="Correo"
                                                            aria-label="Center Align"
                                                            onClick={() => handleEmail(trabajo.id)}
                                                            >
                                                            <i className="bi bi-envelope-fill"></i>
                                                        </button>
                                                        <button
                                                            className='btn btn-danger btn-sm m-1'
                                                            title="Eliminar"
                                                            aria-label="Center Align"
                                                            onClick={() => handleDelete(trabajo.id)}
                                                        >
                                                            <i className="bi bi-trash-fill"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table >
                        </div >
                    </>
                )
            }
        </>
    )
}