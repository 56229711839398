import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams, Link } from 'react-router-dom';
import CrmFinder from '../api/CrmFinder';
import Container from './layout/Container';

export default function EditarTrabajo(props) {
    const { id } = useParams();
    let history = useHistory();
    const [cliente, setCliente] = useState('');
    const [maquina, setMaquina] = useState('');
    const [material, setMaterial] = useState('');
    const [resolucion, setResolucion] = useState('');
    const [terminado, setTerminado] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [referencia, setReferencia] = useState('');
    const [tamaño, setTamaño] = useState('');
    const [remision, setRemision] = useState('');
    const [observacion, setObservacion] = useState('');
    const [facturado, setFacturado] = useState('false');
    const [impreso, setImpreso] = useState('false');
    const [finalizado, setFinalizado] = useState('false');
    const [entregado, setEntregado] = useState('false');
    const [archivado, setArchivado] = useState('false');

    useEffect(() => {
        const fetchData = async () => {
            const response = await CrmFinder.get(`/trabajos/${id}`);
            setCliente(response.data.data.trabajo.cliente);
            setMaterial(response.data.data.trabajo.material);
            setResolucion(response.data.data.trabajo.resolucion);
            setTerminado(response.data.data.trabajo.terminado);
            setMaquina(response.data.data.trabajo.maquina);
            setCantidad(response.data.data.trabajo.cantidad);
            setReferencia(response.data.data.trabajo.referencia);
            setTamaño(response.data.data.trabajo.tamaño);
            setRemision(response.data.data.trabajo.remision);
            setObservacion(response.data.data.trabajo.observacion);
            setFacturado(response.data.data.trabajo.facturado);
            setImpreso(response.data.data.trabajo.impreso);
            setFinalizado(response.data.data.trabajo.finalizado);
            setEntregado(response.data.data.trabajo.entregado);
            setArchivado(response.data.data.trabajo.archivado);
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleFacturado = () => setFacturado(value => !value);
    const handleImpreso = () => setImpreso(value => !value);
    const handleFinalizado = () => setFinalizado(value => !value);
    const handleEntregado = () => setEntregado(value => !value);
    const handleArchivado = () => setArchivado(value => !value);

    const guardarCambios = async (e) => {
        e.preventDefault();
        try {
            // eslint-disable-next-line
            const actualizarCliente = await CrmFinder.put(`/trabajos/${id}/editar`, {
                material,
                referencia,
                resolucion,
                terminado,
                cantidad,
                tamaño,
                remision,
                observacion,
                facturado,
                impreso,
                finalizado,
                entregado,
                archivado
            });
            toast.success((t) => (
                <span className='fw-bold'>El trabajo se actualizó</span>
            ));
        } catch (error) {
            console.log(error);
            toast((t) => (
                <span className='fw-bold'>No se realizaron los cambios</span>
            ), { icon: '❗' });
        }
        history.push('/');
    }

    return (
        <Container>
            <div className='container-sm'>
                <Link
                    className="btn btn-outline-dark fw-bold"
                    aria-current="page"
                    to="/"
                >
                    Volver a los trabajos
                </Link>
                <h2 className='text-center mb-3 fw-normal'>Editar trabajo de <span className='fw-bold text-uppercase'>{cliente}</span></h2>
                <form>
                    <div className='row'>
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="cliente"
                                    placeholder="cliente"
                                    disabled
                                    value={cliente}
                                />
                                <label htmlFor="cliente">Cliente</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="referencia"
                                    placeholder="referencia"
                                    disabled
                                    value={maquina}
                                />
                                <label htmlFor="referencia">Maquina</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="material"
                                    placeholder="material"
                                    value={material}
                                    onChange={e => setMaterial(e.target.value)}
                                />
                                <label htmlFor="material">Material</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="referencia"
                                    placeholder="referencia"
                                    value={referencia}
                                    onChange={e => setReferencia(e.target.value)}
                                />
                                <label htmlFor="referencia">Referencia</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="resolucion"
                                    placeholder="resolucion"
                                    value={resolucion}
                                    onChange={e => setResolucion(e.target.value)}
                                />
                                <label htmlFor="resolucion">Resolución</label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="terminado"
                                    placeholder="terminado"
                                    value={terminado}
                                    onChange={e => setTerminado(e.target.value)}
                                />
                                <label htmlFor="terminado">Terminado</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="cantidad"
                                    placeholder="cantidad"
                                    value={cantidad}
                                    onChange={e => setCantidad(e.target.value)}
                                />
                                <label htmlFor="cantidad">Cantidad</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="tamaño"
                                    placeholder="tamaño"
                                    value={tamaño}
                                    onChange={e => setTamaño(e.target.value)}
                                />
                                <label htmlFor="tamaño">Tamaño</label>
                            </div>

                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control fw-bold"
                            id="remision"
                            placeholder="Remisión"
                            value={remision}
                            onChange={e => setRemision(e.target.value)}
                        />
                        <label htmlFor="remision">Remisión</label>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea
                            className="form-control fw-bold"
                            placeholder="Observacion"
                            id="observacion"
                            style={{ height: '100px' }}
                            value={observacion}
                            onChange={e => setObservacion(e.target.value)}
                        ></textarea>
                        <label htmlFor="observacion">Observación</label>
                    </div>
                    <div className="d-flex mb-3 justify-content-between align-items-center flex-wrap">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="facturado"
                                checked={facturado}
                                onChange={handleFacturado}
                            />
                            <label className="form-check-label" htmlFor="facturado">Facturado</label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="impreso"
                                checked={impreso}
                                onChange={handleImpreso}
                            />
                            <label className="form-check-label" htmlFor="impreso">Impreso</label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="finalizado"
                                checked={finalizado}
                                onChange={handleFinalizado}
                            />
                            <label className="form-check-label" htmlFor="finalizado">Finalizado</label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="entregado"
                                checked={entregado}
                                onChange={handleEntregado}
                            />
                            <label className="form-check-label" htmlFor="entregado">Entregado</label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="archivado"
                                checked={archivado}
                                onChange={handleArchivado}
                            />
                            <label className="form-check-label" htmlFor="archivado">Achivado</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button
                            className="btn btn-primary fw-bold"
                            type="submit"
                            onClick={guardarCambios}
                        >Guardar</button>
                    </div>
                </form>
            </div>
        </Container>
    )
}
